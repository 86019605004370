import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import HomePageStudentBlock from './components/HomePageStudentBlock';
import HomePageOurProduct from './components/HomePageOurProduct';
import Card from './components/Card';

function App() {
  return (
      <>
        <Navbar />

        {/*<Card />
        <HomePageStudentBlock />
        {/* <HomePageOurProduct /> */}
      </>
  );
}

export default App;
